import * as React from 'react';
import { flow } from 'lodash';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useDispatch, useSelector } from 'react-redux';

import messagesSchema from '@giro/shared-constants/messagesSchema.constant';

import FieldPhoneComponent from '@giro/shared-components/Fields/FieldPhone.component';

import useDialogHook from '@giro-pdv-whitelabel/hooks/useDialogRedux.hook';

import FieldLabelFormikComponent from '@giro-pdv-whitelabel/components/FieldLabelFormik.component';
import TextFieldLabelComponent from '@giro-pdv-whitelabel/components/TextFieldLabel.component';

import dialogSendPayment from '../store/dialogs/dialogSendPayment';
import { IconButton } from '@mui/material';

import auth from '@giro/shared-store/auth';

export default function DialogSendPaymentComponent() {
  const dispatch = useDispatch();

  const dispatchRedux = {
    servicePost: flow(dialogSendPayment.action.servicePost, dispatch),
  };

  const selectorRedux = {
    loading: useSelector(dialogSendPayment.selector.selectLoading),
    data: useSelector(dialogSendPayment.selector.selectState),
    user: useSelector(auth.selector.selectUser),
  };

  const { handleClose, visible } = useDialogHook(dialogSendPayment);

  const schema = Yup.object({
    type: Yup.string().required(messagesSchema.REQUIRED).default('email'),
    destination: Yup.string()
      .when('type', {
        is: 'email',
        then: Yup.string()
          .email(messagesSchema.EMAIL)
          .required(messagesSchema.REQUIRED),
      })
      .when('type', {
        is: 'whatsapp',
        then: Yup.string()
          .required(messagesSchema.REQUIRED)
          .min(11, messagesSchema.MIN),
      }),
  });

  const initialValues = {
    type: 'Email',
    destination: '',
  };

  const onSubmit = async (values) => {
    dispatchRedux.servicePost(values);

    return true;
  };

  const link = `https://pagamentos.sipe.registrocivil.org.br/debts/${selectorRedux.user?.user?.external_code}/${selectorRedux?.data?.reference_id}`;

  return (
    <Dialog open={visible} onClose={handleClose}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnMount
        validationSchema={schema}
      >
        {({ errors, values, setFieldValue, submitForm }) => (
          <Form>
            <DialogTitle>
              <Typography variant="trasso_body_medium">
                Link de pagamento
              </Typography>
            </DialogTitle>
            <DialogContent sx={{ minWidth: 500 }}>
              <DialogContentText>
                <Link href={link} target="_blank" variant="caption">
                  {link}
                </Link>
                <Tooltip title="Copiar texto">
                  <IconButton
                    color="secondary"
                    onClick={() => navigator.clipboard.writeText(link)}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </Tooltip>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Fechar</Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

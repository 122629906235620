import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';

import tablePayment from '../store/tablePayment';
import fees from '../store/fees';
import clients from '../store/clients';
import transactions from '../store/transactions';

const enumTipoTransacao = {
  client: 3,
  ec: 2,
};

const formatToCurrency = (number) =>
  Number(number).toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });

const TablePaymentComponent = () => {
  const dispatch = useDispatch();

  const dispatchRedux = {
    resetState: flow(tablePayment.action.resetState, dispatch),
    updateInstallmentSelected: flow(
      tablePayment.action.updateInstallmentSelected,
      dispatch
    ),
  };

  const selectorRedux = {
    dataClients: useSelector(clients.selector.selectPayload),
    amount: useSelector(tablePayment.selector.selectAmount),
    installmentSelected: useSelector(
      tablePayment.selector.selectInstallmentSelected
    ),
    typeTax: useSelector(tablePayment.selector.selectTypeTax),
    typeTaxCredit: useSelector(tablePayment.selector.selectTypeTaxCredit),
    return: useSelector(tablePayment.selector.selectReturn),
    feesData: useSelector(fees.selector.selectData),
    feesLoading: useSelector(fees.selector.selectLoading),
    transactionsLoading: useSelector(transactions.selector.selectLoading),
  };

  React.useEffect(() => {
    return () => {
      dispatchRedux.resetState();
    };
  }, []);

  const { retornoGiro } = selectorRedux.dataClients || {};

  const idTipoTransacaoDebit = enumTipoTransacao[selectorRedux.typeTax];
  const idTipoTransacaoCredit = enumTipoTransacao[selectorRedux.typeTaxCredit];

  const ECTYPETAX = 2;

  const isPixFn = (r) => r.cdTipoTaxa === 3;
  const isDebitFn = (r) => r.cdTipoTaxa === 1;
  const isCreditFn = (r) => r.cdTipoTaxa === 2;

  const installments = selectorRedux.feesData?.filter(
    (f) =>
      (f.cdTipoTransacao === idTipoTransacaoDebit && isDebitFn(f)) ||
      (f.cdTipoTransacao === idTipoTransacaoCredit && isCreditFn(f)) ||
      (f.cdTipoTransacao === ECTYPETAX && isPixFn(f))
  );

  const rows = installments?.map((r, idx) => {
    let installment_amount,
      amount,
      returnAmount = 0;

    const isDebit = isDebitFn(r);
    const isPix = isPixFn(r);
    const isCredit = isCreditFn(r);

    if (selectorRedux.return > 0) {
      const returnPlusGiro = selectorRedux.return >= 8 ? 0.5 : 0;
      const returnEc = Number(selectorRedux.return);
      const returnGiro = retornoGiro ? returnEc * 0.25 + returnPlusGiro : 0;

      returnAmount =
        isDebit || isPix
          ? 0
          : selectorRedux.amount * ((selectorRedux.return + returnGiro) / 100);
    }

    if (isDebit && selectorRedux.typeTax === 'ec') {
      installment_amount = selectorRedux.amount / r.nuParcelas;
      amount = r.vlFator * selectorRedux.amount * r.nuParcelas;
    }

    if (isDebit && selectorRedux.typeTax === 'client') {
      installment_amount = r.vlFator * (selectorRedux.amount + returnAmount);
      amount = r.vlFator * (selectorRedux.amount + returnAmount) * r.nuParcelas;
    }

    if (isCredit && selectorRedux.typeTaxCredit === 'ec') {
      installment_amount = selectorRedux.amount / r.nuParcelas;
      amount = r.vlFator * selectorRedux.amount * r.nuParcelas;
    }

    if (isCredit && selectorRedux.typeTaxCredit === 'client') {
      installment_amount = r.vlFator * (selectorRedux.amount + returnAmount);
      amount = r.vlFator * (selectorRedux.amount + returnAmount) * r.nuParcelas;
    }

    if (isPix) {
      installment_amount = selectorRedux.amount;
      amount = r.vlFator * selectorRedux.amount * r.nuParcelas;
    }

    const installment = isDebit || isPix ? 'Única' : r.nuParcelas;

    return {
      key: `installment_${idx}`,
      type: isPix ? 'PIX' : isDebit ? 'Débito' : 'Crédito',
      installment,
      installment_amount: formatToCurrency(installment_amount),
      amount: formatToCurrency(amount),
      amount_number: amount,
      installment_amount_number: installment_amount,
    };
  });

  useEffect(() => {
    dispatch(tablePayment.action.updateRows(rows));
  }, [rows]);

  const hasPix = rows?.some((r) => r.type === 'PIX');

  return (
    <Box position="relative" overflow="hidden">
      <Box
        display="flex"
        flexDirection="column"
        gap={0.5}
        pr={2}
        position="sticky"
        top={0}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography variant="trasso_heading_small" color="grey.900">
            Formas de pagamento
          </Typography>
          <Typography variant="trasso_body_small" color="trasso.900">
            Em até {installments?.length - (hasPix ? 2 : 1)} parcelas
          </Typography>
        </Box>
        <Box
          border="1px solid transparent"
          borderColor="grey.300"
          borderRadius="6px"
        >
          <TableContainer>
            <Table
              sx={{
                minWidth: '400px',
              }}
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: 35 }}>
                    <Typography
                      variant="trasso_heading_extra_small"
                      color="grey.900"
                    >
                      Tipo
                    </Typography>
                  </TableCell>
                  <TableCell style={{ width: 35 }}>
                    <Typography
                      variant="trasso_heading_extra_small"
                      color="grey.900"
                    >
                      Parcelas
                    </Typography>
                  </TableCell>
                  <TableCell style={{ width: 35 }}>
                    <Typography
                      variant="trasso_heading_extra_small"
                      color="grey.900"
                    >
                      Valor parcela
                    </Typography>
                  </TableCell>
                  <TableCell style={{ width: 35 }}>
                    <Typography
                      variant="trasso_heading_extra_small"
                      color="grey.900"
                    >
                      Valor total
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.map((row, index) => (
                  <TableRow
                    key={row.key}
                    onClick={() => {
                      dispatchRedux.updateInstallmentSelected(row);
                    }}
                    sx={{
                      cursor: 'pointer',
                      '&:nth-of-type(odd)': {
                        backgroundColor:
                          selectorRedux?.installmentSelected?.key === row.key
                            ? 'grey.300'
                            : 'grey.100',
                      },
                      '&:nth-of-type(even)': {
                        backgroundColor:
                          selectorRedux?.installmentSelected?.key === row.key
                            ? 'grey.300'
                            : 'white',
                      },
                      '&:last-child td, &:last-child th': {
                        border: 0,
                      },

                      '&:hover': {
                        backgroundColor: 'grey.300',
                      },
                    }}
                  >
                    <TableCell component="th" scope="row" style={{ width: 35 }}>
                      <Typography
                        variant="trasso_body_extra_small"
                        color="grey.900"
                      >
                        {row.type}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ width: 35 }}>
                      <Typography
                        variant="trasso_body_extra_small"
                        color="grey.900"
                      >
                        {row.installment}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ width: 35 }}>
                      <Typography
                        variant="trasso_body_extra_small"
                        color="grey.900"
                      >
                        {row.installment_amount}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ width: 35 }}>
                      <Typography
                        variant="trasso_body_extra_small"
                        color="grey.900"
                      >
                        {row.amount}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        {selectorRedux.amount === 0 && (
          <Box
            position="absolute"
            top={0}
            left={0}
            height="100%"
            minHeight="500px"
            bgcolor="#ffffffe0"
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography color="#808080">Informe um valor</Typography>
          </Box>
        )}

        {selectorRedux.transactionsLoading && (
          <Box
            position="absolute"
            top={0}
            left={0}
            height="100%"
            minHeight="500px"
            bgcolor="#ffffffe0"
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          />
        )}

        {selectorRedux.feesLoading && (
          <Box
            position="absolute"
            top={0}
            left={0}
            height="100%"
            minHeight="500px"
            bgcolor="white"
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TablePaymentComponent;

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(type: string, amount) {
  return { type, amount };
}

const rows = [
  createData('PIX', '0,70%*'),
  createData('Débito', '0,70%'),
  createData('Crédito', '1,70%**'),
];

const DialogTaxComponent = ({ handleClose, visible }) => {
  return (
    <Dialog open={visible} onClose={handleClose}>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Typography variant="trasso_body_medium">Tabela de taxas</Typography>
        </Box>
      </DialogTitle>
      <Box
        minWidth={{
          xs: 0,
          md: 400,
        }}
        display="flex"
        flexDirection="column"
        gap={2}
        px={3}
        pb={3}
        position="relative"
      >
        <Box display="flex" flexDirection="column" gap={2}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="trasso_heading_small">Tipo</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="trasso_heading_small">
                      Taxa {`(CET)`}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.type}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Typography variant="trasso_body_small">
                        {row.type}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="trasso_body_small">
                        {row.amount}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Button variant="contained" onClick={handleClose}>
          Fechar
        </Button>

        <Typography variant="caption" color="grey.700">
          *Atendendo ao Provimento 127/22 (CNJ), o Pix é suportado integralmente
          pela Serventia.
        </Typography>
        <Typography variant="caption" color="grey.700">
          **Taxa adicional para antecipação D+1
        </Typography>
        <Typography variant="caption" color="grey.700">
          É autorizado o repasse destes custos para o usuário final, nos termos
          do Provimento 127 da Corregedoria Nacional de Justiça.
        </Typography>
      </Box>
    </Dialog>
  );
};

export default DialogTaxComponent;
